
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
		IonRefresher,
		IonRefresherContent
	},
	methods: {
		getUserPath: function(item: any, myself: any){
			if(myself.id == item.recipient.id){
				return "/user/" + item.user.name;
			} else {
				return "/user/" + item.recipient.name;
			}
		},
		getPostPath: function(item: any, myself: any){
			if(myself.id == item.recipient.id){
				return "/im/" + item.user.name;
			} else {
				return "/im/" + item.recipient.name;
			}
		},
		getUserAvatar: function(item: any, myself: any){
			if(myself.id == item.recipient.id){
				return item.user.avatar ? "//i0.beon.fun/" + item.user.path + "/avatars/" + item.user.avatar.idx + "." + item.user.avatar.ext : '';
			} else {
				return item.recipient.avatar ? "//i0.beon.fun/" + item.recipient.path + "/avatars/" + item.recipient.avatar.idx + "." + item.recipient.avatar.ext : '';
			}
		},
		getUserNick(item: any, myself: any){
			if(myself.id == item.recipient.id){
				return item.user.nickname;
			} else {
				return item.recipient.nickname;
			}
		},
		getUserSign(item: any, myself: any){
			if(myself.id == item.recipient.id){
				return item.user.sign;
			} else {
				return item.recipient.sign;
			}
		}
	},
  setup() {
    const route = useRoute();
    const folder = "Личные сообщения";
	const dialogs = ref({});
	const chats = ref({});
	const myself = ref({});
	const { token, getRichText } = useDataService();
	
	const doRefresh = function(){
		axios.get('https://beon.fun/api/v1/im',{
		headers: {
			Authorization: 'Bearer ' + token.value,
		}})
		.then(response => (
			dialogs.value = response.data.messages,
			myself.value = response.data.self,
			chats.value = response.data.chats
		));
	};
	doRefresh();
    return { folder, dialogs, chats, myself, getRichText, doRefresh }
  }
}
