<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ folder }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ folder }}</ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
		<ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
			<ion-refresher-content></ion-refresher-content>
		</ion-refresher>
        <ion-list>
			<ion-item v-if="chats">
				<ion-list style="width:100%">
					<ion-item>Чаты</ion-item>
					<ion-item class="ion-no-padding" v-for="item in chats" v-bind:key='item.id' @click="() => $router.push('/chats/'+item.id)">
						{{item.name}}
					</ion-item>
				</ion-list>
			</ion-item>
            <ion-item class="ion-no-padding" v-for="item in dialogs" v-bind:key='item.id'>
                <div class="post">
                    <div class="post__header">
                        <span class="post__date">{{item.created_at}}</span>
						<span v-if="item.is_read===0">
							<span v-if="item.user_id === myself.id" class="message__unread message__unread_new">не прочитано</span>
							<span v-else class="message__unread">новое!</span>
						</span>
                    </div>
                    <div class="post__body">
                        <div class="post__author" @click="() => $router.push(getUserPath(item, myself))">
                            <img v-bind:src="getUserAvatar(item, myself)">
                            <div class="post__username">{{getUserNick(item, myself)}}</div>
                            <div class="post__sign">{{getUserSign(item, myself)}}</div>
                        </div>
                        <div class="post__text" v-html="getRichText(item.message)" @click="() => $router.push(getPostPath(item, myself))" router-link="getPostPath(item, myself)">
                        </div>
                    </div>
                </div>
            </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-menu-button {
  color: var(--ion-color-primary);
}
ion-item{
    --inner-padding-end: 0.5%;
    --padding-start:0.5%;
	padding-bottom: 20px;
}
.message__unread {
	font-size: 75%;
	margin-left: 1rem;
    background-color: #71ff714a;
}
.message__unread_new {
	background-color: #71ebff4a;
}
</style>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
		IonRefresher,
		IonRefresherContent
	},
	methods: {
		getUserPath: function(item: any, myself: any){
			if(myself.id == item.recipient.id){
				return "/user/" + item.user.name;
			} else {
				return "/user/" + item.recipient.name;
			}
		},
		getPostPath: function(item: any, myself: any){
			if(myself.id == item.recipient.id){
				return "/im/" + item.user.name;
			} else {
				return "/im/" + item.recipient.name;
			}
		},
		getUserAvatar: function(item: any, myself: any){
			if(myself.id == item.recipient.id){
				return item.user.avatar ? "//i0.beon.fun/" + item.user.path + "/avatars/" + item.user.avatar.idx + "." + item.user.avatar.ext : '';
			} else {
				return item.recipient.avatar ? "//i0.beon.fun/" + item.recipient.path + "/avatars/" + item.recipient.avatar.idx + "." + item.recipient.avatar.ext : '';
			}
		},
		getUserNick(item: any, myself: any){
			if(myself.id == item.recipient.id){
				return item.user.nickname;
			} else {
				return item.recipient.nickname;
			}
		},
		getUserSign(item: any, myself: any){
			if(myself.id == item.recipient.id){
				return item.user.sign;
			} else {
				return item.recipient.sign;
			}
		}
	},
  setup() {
    const route = useRoute();
    const folder = "Личные сообщения";
	const dialogs = ref({});
	const chats = ref({});
	const myself = ref({});
	const { token, getRichText } = useDataService();
	
	const doRefresh = function(){
		axios.get('https://beon.fun/api/v1/im',{
		headers: {
			Authorization: 'Bearer ' + token.value,
		}})
		.then(response => (
			dialogs.value = response.data.messages,
			myself.value = response.data.self,
			chats.value = response.data.chats
		));
	};
	doRefresh();
    return { folder, dialogs, chats, myself, getRichText, doRefresh }
  }
}
</script>